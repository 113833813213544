<template>
  <div class="flex justify-center w-full">
    <div class="w-1/2">
      <div class="flex flex-col items-center p-5">
        <div class="flex flex-col justify-center my-5 space-y-16">
          <h1 class="text-primary-dark">
            Admin Login
          </h1>
          <div class="">
            <button
              class="flex items-center px-5 py-3 mb-3 text-sm font-medium leading-snug text-black uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              @click="login"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                class="mx-2 w-11"
              ><path
                fill="#4CAF50"
                d="M8.5 7.5H16v-7a.5.5 0 0 0-.5-.5h-7v7.5z"
              /><path
                fill="#F44336"
                d="M7.5 7.5V0h-7a.5.5 0 0 0-.5.5v7h7.5z"
              /><path
                fill="#2196F3"
                d="M7.5 8.5H0v7a.5.5 0 0 0 .5.5h7V8.5z"
              /><path
                fill="#FFC107"
                d="M8.5 8.5V16h7a.5.5 0 0 0 .5-.5v-7H8.5z"
              /></svg><p class="text-base font-bold">
                Continue with Microsoft
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { loginRequest } from '@/plugins/Azure'

export default {
  data () {
    return {
      email: '',
      password: '',
      verifyEmailMsg: false,
      errorMsg: false
    }
  },
  methods: {
    async login () {
      this.$store.commit('USER/LOGOUT_USER')
      this.$store.commit('ADMIN/LOGOUT_ADMIN')
      this.$chargebee.logout()
      const loginResponse = await this.$msal.loginPopup(loginRequest)
      try {
        await this.$store.dispatch('ADMIN/msLoginAdmin', loginResponse)
        const getAllAccounts = await this.$msal.getAllAccounts()
        this.$msal.setActiveAccount(getAllAccounts[0])
        this.$router.push({ name: 'Customers' })
      } catch (error) {
        console.log('error', error)
      }
    }
  }
}
</script>

<style>

</style>
